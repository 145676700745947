const awsconfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_onxDnk301',
    userPoolWebClientId: '6m55mnvjtjaub13rg8ll3lcee3',
    oauth: {
      domain: 'movie-picker.auth.us-east-1.amazoncognito.com',
      scope: [
        'email','phone','profile','openid'
      ],
      redirectSignIn: 'https://movies.xilehome.life/',
      redirectSignOut: 'https://movies.xilehome.life/',
      // redirectSignIn: 'http://localhost:3000/',
      // redirectSignOut: 'https://localhost:3000/',
      clientId: '6m55mnvjtjaub13rg8ll3lcee3',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
};

export default awsconfig;
