import React, { useState } from 'react';
import ReactModal from 'react-modal';
import './AddMovieModal.css';


const AddMovieModal = ({ isOpen, onClose }) => {
  const [title, setTitle] = useState('');
  const [genre, setGenre] = useState('');
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [imdbLink, setImdbLink] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Perform form validation and handle the submission
    if (!title || !genre || !description || !language || !imdbLink) {
      // If any of the fields are empty, display an error message or perform other error handling
      return;
    }

    // Create an object with the movie data
    const newMovie = {
      title,
      genre,
      desc: description,
      language,
      imdb_url: imdbLink,
    };

    setSubmitting(true);

    try {
      const response = await fetch('https://2g9bt76np9.execute-api.us-east-1.amazonaws.com/dev/movies', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMovie),
      });

      if (response.ok) {
        alert(`Movie: ${title} was added!`);
      } else {
        const errData = await response.json()
        alert(`Failed to add movie: ${title}. ${errData.message}`);
      }
    } catch (error) {
      // Handle any network or request errors
      alert('Error: ' + error.message);
    }

    setSubmitting(false);
    // Clear the form fields and close the modal
    setTitle('');
    setGenre('');
    setDescription('');
    setLanguage('');
    setImdbLink('');
    onClose();
  };


  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="add-movie-modal"
      overlayClassName="add-movie-modal-overlay"
    >
      <h2>Add a Movie</h2>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label>Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div>
          <label>Genre:</label>
          <input
            type="text"
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div>
          <label>Language:</label>
          <input
            type="text"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          />
        </div>
        <div>
          <label>IMDb Link:</label>
          <input
            type="text"
            value={imdbLink}
            onChange={(e) => setImdbLink(e.target.value)}
          />
        </div>
        {submitting && <p className="submission-status">Submitting...</p>}
        <button type="submit" disabled={submitting}>
          Submit
        </button>
      </form>
    </ReactModal>
  );
};

export default AddMovieModal;
