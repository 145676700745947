import React, { useState, useEffect } from 'react';
import {
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Button,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'


const MovieStatsModal = ({ isOpen, onClose, genre }) => {
  const [movies, setMovies] = useState([]);
  const [nextKey, setNextKey] = useState('');
  const [prevKey, setPrevKey] = useState('');
  const [lastFetchedMovies, setLastFetchedMovies] = useState([]);

  useEffect(() => {
    fetchMovies();
  }, [isOpen, genre]);

  const fetchMovies = async (nextKey, reverse = false) => {
    try {
      let url = `https://2g9bt76np9.execute-api.us-east-1.amazonaws.com/dev/movies?genre=${genre}`;
      if (nextKey) {
        url += `&start_key=${nextKey}`;
      }

      if (reverse) {
        url += `&reverse=${reverse}`;
      }

      const response = await fetch(url);
      const data = await response.json();

      if (data.Items.length > 0) {
        // If the response has movies, update the state and next/prev keys
        setMovies(data.Items);
        setNextKey(data.Items[data.Items.length - 1].movie_id);
        setPrevKey(data.Items[0].movie_id);

        // Save the fetched movies to lastFetchedMovies
        setLastFetchedMovies(data.Items);
      } else {
        // If the response is empty, show the last fetched movies
        setMovies(lastFetchedMovies);
      }
    } catch (error) {
      console.error('Error fetching movies:', error);
    }
  };

  const handlePrevious = () => {
    fetchMovies(prevKey, true);
  };

  const handleNext = () => {
    fetchMovies(nextKey);
  };

  const notWatchedColor = useColorModeValue('yellow', 'orange');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Movie Statistics for {genre}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Description</Th>
                <Th>IMDb Link</Th>
              </Tr>
            </Thead>
            <Tbody>
              {movies && movies.map((movie) => (
                <Tr key={movie.title}>
                  <Td>{movie.title}</Td>
                  <Td>{movie.desc}</Td>
                  <Td>
                    <Link href={movie.imdb_url} isExternal>
                      IMDb/Douban Link <ExternalLinkIcon mx='2px' />
                    </Link>
                  </Td>
                  <Td>
                    {movie.archived ? (
                      <Tag colorScheme="green">Watched</Tag>
                    ) : (
                      <Tag colorScheme={notWatchedColor}>Not Watched</Tag>
                    )}
                </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            disabled={!prevKey}
            onClick={handlePrevious}
          >
            Previous
          </Button>
          <Button colorScheme="blue" mr={3} disabled={!nextKey} onClick={handleNext}>
            Next
          </Button>
          <Button colorScheme="gray" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MovieStatsModal;
