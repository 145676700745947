import React, { useState } from 'react';
import { Button, Flex, Box, Heading } from '@chakra-ui/react';
import AddMovieModal from './AddMovieModal';
import PickMovieModal from './PickMovieModal';


const MoviePicker = () => {
  const [selectedMovie, setSelectedMovie] = useState('');
  const [isAddMovieModalOpen, setIsAddMovieModalOpen] = useState(false);
  const [isPickMovieModalOpen, setIsPickMovieModalOpen] = useState(false);

  const handleAddMovie = () => {
    setIsAddMovieModalOpen(true);
  };

  const handleCloseAddMovieModal = () => {
    setIsAddMovieModalOpen(false);
  };

  const handlePickMovie = () => {
    setIsPickMovieModalOpen(true);
  };

  const handleClosePickMovieModal = () => {
    setIsPickMovieModalOpen(false);
  };


  return (
    <Box p={4}>
      <Flex direction="column" align="center" justify="center" height="100vh">
        <Button
          colorScheme="blue"
          size="lg"
          mb={4}
          onClick={handlePickMovie}
        >
          Pick a Movie
        </Button>
        <Button colorScheme="green" size="lg" onClick={handleAddMovie}>
          Add a Movie
        </Button>
        {selectedMovie && (
          <p className="movie-picker__selected-movie">
            Selected Movie: {selectedMovie}
          </p>
        )}
      </Flex>
      <PickMovieModal
        isOpen={isPickMovieModalOpen}
        onClose={handleClosePickMovieModal}
      />
      <AddMovieModal
        isOpen={isAddMovieModalOpen}
        onClose={handleCloseAddMovieModal}
      />
    </Box>
  );
};

export default MoviePicker;
