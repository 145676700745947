import React, { useState, useEffect } from 'react';
import { Box, Center, Heading, Flex, Wrap, WrapItem, Text, Progress, useDisclosure } from '@chakra-ui/react';
import MovieStatsModal from './MovieStatsModal';

const MovieStats = () => {
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const fetchGenres = async () => {
    try {
      const response = await fetch('https://2g9bt76np9.execute-api.us-east-1.amazonaws.com/dev/genres');
      const data = await response.json();
      setGenres(data.genres);
      console.log(genres);
    } catch (error) {
      console.error('Error fetching genres:', error);
    }
  };

  useEffect(() => {
    fetchGenres();
  }, []);

  const handleGenreClick = (genre) => {
    setSelectedGenre(genre);
    onOpen();
  };

  return (
    <Center>
      <Box w="1000px">
        <Heading as="h2" size="lg" textAlign="center">
          Movie Stats
        </Heading>
        <Box mt={6} textAlign="center">
          <Wrap spacing={4}>
            {genres.map((genre, index) => (
              <WrapItem key={index}>
                <Flex
                  direction="column"
                  alignItems="center"
                  bg="white"
                  shadow="md"
                  borderRadius="md"
                  p={4}
                  cursor="pointer"
                  onClick={() => handleGenreClick(genre.genre)}
                >
                  <Text fontSize="xl">{genre.genre}</Text>
                  <Text>Total: {genre.stats.total}</Text>
                  <Text>Watched: {genre.stats.watched}</Text>
                  <Progress
                    mt={2}
                    value={(genre.stats.watched / genre.stats.total) * 100}
                    size="sm"
                    colorScheme="green"
                    width="100%"
                  />
                </Flex>
              </WrapItem>
            ))}
          </Wrap>
          <MovieStatsModal isOpen={isOpen} onClose={onClose} genre={selectedGenre} />
        </Box>
      </Box>
    </Center>
  );
};

export default MovieStats;
