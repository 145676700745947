import React, { useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import { Button, Box, Flex, Heading } from '@chakra-ui/react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react'
import MoviePicker from './MoviePicker';
import MovieStats from './MovieStats';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      console.log(error);
      setIsAuthenticated(false);
    }
  };

  const handleLogin = async () => {
    try {
      await Auth.federatedSignIn({ provider: 'Google' });
    } catch (error) {
      console.log('Error signing in:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };


  return (
    <Router>
      <Box p={4}>
        <Flex align="center" justify="space-between" bg="white.500" p={2} mb={4}>
          <Heading as="h1" color="bronze" size="xl" textAlign="left">
            看电影分歧终端机
          </Heading>
          {isAuthenticated ? (
            <>
            <Breadcrumb separator=" " fontWeight='light' fontSize='lg' ml={16} flexGrow={1}>
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/picker">Movie Picker</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/stats">Movie Stats</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Button colorScheme="red" onClick={handleSignOut}>
              Sign Out
            </Button>
            </>
          ) : (
            <Button colorScheme="green" onClick={handleLogin}>
              Sign In
            </Button>
          )}
        </Flex>
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<MoviePicker />} />
              <Route path="/picker" element={<MoviePicker />} />
              <Route path="/stats" element={<MovieStats />} />
            </>
          ) : (
            <Route path="/" element={<Navigate to="/" />} />
          )}
        </Routes>
      </Box>
    </Router>
  );
};

export default App;
