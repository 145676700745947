import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import './PickMovieModal.css';


const PickMovieModal = ({ isOpen, onClose }) => {
  const [genres, setGenres] = useState(['Surprise Me']);
  const [selectedGenre, setSelectedGenre] = useState('');
  const [pickMovieErr, setPickMovieErr] = useState(false);
  const [pickErrMsg, setPickErrMsg] = useState('');
  const [movieDetails, setMovieDetails] = useState({});
  const [picked, setPicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [genresFetched, setGenresFetched] = useState(false);

  const fetchGenres = async () => {
    try {
      const response = await fetch('https://2g9bt76np9.execute-api.us-east-1.amazonaws.com/dev/genres');
      const data = await response.json();
      setGenres([...genres, ...data.genres.map((genreData) => genreData.genre)]);
      setGenresFetched(true);
    } catch (error) {
      console.error('Error fetching genres:', error);
    }
  };

  useEffect(() => {
    if (isOpen && !genresFetched) {
      fetchGenres();
    } else {
      setGenres(['Surprise Me']);
      setPicked(false);
      setPickErrMsg('');
      setMovieDetails({});
      setPickMovieErr(false);
      setSelectedGenre('');
      setGenresFetched(false);
    }
  }, [isOpen]);



  const handleGenreSelect = (event) => {
    setSelectedGenre(event.target.value);
  };

  const handlePickMovie = async () => {
    setIsLoading(true);
    console.log('Selected genre:', selectedGenre);
    var url;
    try {
      if (selectedGenre !== 'Surprise Me') {
        const queryParams = new URLSearchParams({
          genre: selectedGenre,
        }).toString();
        url = `https://2g9bt76np9.execute-api.us-east-1.amazonaws.com/dev/movies/random?${queryParams}`;
      } else {
        url = 'https://2g9bt76np9.execute-api.us-east-1.amazonaws.com/dev/movies/random';
      }
      const response = await fetch(url);
      const data = await response.json();
      setMovieDetails(data['Items'][0]);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      setPicked(true);
    } catch (err) {
      console.error('Error fetching genres:', err);
      setPickErrMsg(err);
      setPickMovieErr(true);
      setIsLoading(false);
    }
  };

  const handlePickIt = async () => {
    try {
      const response = await fetch('https://2g9bt76np9.execute-api.us-east-1.amazonaws.com/dev/movies/archive', {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
            title: movieDetails.title
        }),
      });
      const statusCode = response.status;
      if (statusCode === 200) {
        alert(`Successfully picked ${movieDetails.title} for this week! It will be removed from movie pool for further picks.`);
      } else {
        const data = await response.json();
        alert('Error picking movie:', data.message);
      }
      onClose();
    } catch (error) {
      console.error('Error picking movie:', error);
      alert(error);
    }
  }


  return (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    className="pick-movie-modal"
    overlayClassName="pick-movie-modal-overlay"
  >
    {picked ? (
      <>
        {!pickMovieErr ? (
          <>
            <h2>{movieDetails.title}</h2>
            <p>Genre: {movieDetails.genre}</p>
            <p>
              IMDb URL: <a href={movieDetails.imdb_url} target="_blank" rel="noopener noreferrer">{movieDetails.imdb_url}</a>
            </p>
            <p>Language: {movieDetails.language}</p>
            <p>Description: {movieDetails.desc}</p>
            <button className="success" onClick={handlePickIt}>
              Pick it!
            </button>
          </>
        ) : (
          <p>Error</p>
        )}
      </>
    ) : (
      <>
        {isLoading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <h2>Select a Genre</h2>
            <select value={selectedGenre} onChange={handleGenreSelect}>
              <option value="">Select Genre</option>
              {genres.map((genre, idx) => (
                <option key={idx} value={genre}>
                  {genre}
                </option>
              ))}
            </select>
            <button disabled={!selectedGenre} onClick={handlePickMovie}>
              Pick
            </button>
            <button onClick={onClose}>Close</button>
          </>
        )}
      </>
    )}
  </ReactModal>
  );
};

export default PickMovieModal;
